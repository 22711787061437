/* You can add global styles to this file, and also import other style files */


.header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0 2px 4px #0000003d;
    background-color: #fff;
    padding-left: 20px;
}

.logo img {
    max-height: 60px;
    margin-right: 6px;
}

.header-nav .nav-profile img {
    height: 36px;
    width: 35px;
}

.header-nav .nav-profile {
    color: #000;
}

.cdetails {
    scroll-behavior: smooth;
    position: relative;
}


.cdetails .topmenu {
    border-bottom: 0.7px solid #bababa;
    box-shadow: 0 8px 8px 0 #8a6b7614;
}

.cdetails .topmenu .link {
    line-height: 40px;
    color: #0d6efd;
    font-weight: 600;
    justify-content: center;
    /* padding: 0px 20px; */
    text-transform: uppercase;
    width: 160px;
    width: 20%;
    display: inline-block;
}


#guide-template #nav-bar {
    position: relative;
    top: 0px;
    width: 100%;
}

#guide-template.fixed #nav-bar {
    position: fixed;
    z-index: 99;
    top: 58px;
    background: #000080;
    left: 0px;
    padding-left: 58px;
}

.guide-template.fixed #nav-bar a.active {
    border-bottom: 2px solid #0084ff;
    color: #0084ff;
}

#guide-template.fixed #nav-bar a {
    color: #fff;
    font-weight: 600;
    line-height: 55px;
}

#mainn {
    background: #FFF;
    margin-top: 40px;
}

#guide-template.fixed #main {
    position: relative;
    top: 50px;
}

.form-control {
    outline: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border-bottom: 1px solid #D3D3D3 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none;
}

.login-form p {
    margin-bottom: 0px;
    margin-right: 10px;
    font-size: 14px;
}

.login {
    padding: 30px 0px;
}
.booking .card-body {
    padding: 8px 10px;
}

.mainss {
    background-color: #fff !important;
}

.mainss .card {
    box-shadow: none;
    border: none;
}

.mainss .card-body {
    padding: 0px !important;
}

.titless {
    margin-bottom: 30px;
}

.mainss .login-form p b {
    font-size: 17px;
    font-weight: 500;
}

.form-left {
    padding: 20px 20px;
    border: 1px solid #d3d3d3;
    margin: 10px 0px 35px 0px;
}

@media (max-width: 992px) {
    .cdetails .topmenu .link {
        line-height: 40px;
        color: #4a4a4a;
        font-weight: 800;
        justify-content: center;
        /* padding: 0px 20px; */
        text-transform: uppercase;
        width: 160px;
        width: 32%;
        display: inline-block;
        text-align: center;
    }
}

.mainss {
    margin-left: 0px !important;
    padding: 70px 20px;
    ;
}

/*
.topmenu a.active {
  background-color: #e94c48;
  box-shadow: inset 0 0 1px #2d0605;
}*/




p.intro {
    line-height: 1.4;
    font-size: 18px;
}

.mainssp {
    background-color: #f2f2f2 !important;
}

.mainssp .card {
    box-shadow: 0 2px 4px #0000003d !important
}

.tab-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px auto;
    padding: 0px;
    background: #eaf5ff;
}

/* Hide the checkbox, do not use display:none as it prevents keyboard users navigating through the tabs */
.tab-container input[type="radio"] {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    border-bottom: 1px solid #eaf5ff;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

/* The label of the input, this is what you see and click on instead of an ugly radio button */
.tab-container label {
    border-bottom: 1px solid #d3d3d3;
    order: 1;
    cursor: pointer;
    border-left: 2px solid #eaf5ff;
    background: #eaf5ff;
    padding: 14px 2px;
    transition: all ease-out 0.2s 0.1s;
}

.tab-container h5 {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0px 11px 10px;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 0px;
}

/* The label "tab" when clicked */
.tab-container input[type="radio"]:checked+label {
    border-left: 4px solid #065af3;
    color: #fff;
    cursor: default;
}

/* How the label appears to keyboard users when it is focused on  */
.tab-container input[type="radio"]:focus+label {
    outline: 1px solid var(--link2-color);
}

/* Panel styles */
.panel {
    order: 99;
    flex-grow: 0;
    flex-basis: 0;
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

/* Show panel when corresponding label is clicked
* This is where your tab panel styles like height, padding, backgrounds and fonts go */
.tab-container input[type="radio"]:checked+label+.panel {
    position: relative;
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transition: opacity ease-out 0.2s 0.1s;
    flex-basis: 100%;
    height: 150px;
    padding: 10px;
    border: 2px solid var(--accent2-color);
    overflow: auto;
}


/* Vertical ID tabs will be vertical instead */
#vertical.tab-container {
    flex-direction: column;
    height: 600px;
    overflow: hidden;
}

#vertical.tab-container label {
    width: 30%;
    flex-basis: unset;
    flex-grow: 0;
    display: flex;
}

#vertical.tab-container label img {
    width: 28px;
    margin-right: 10px;
}

#vertical.tab-container label h6 {
    font-size: 16px;
    font-weight: 700;
    color: #065af3;
    margin-bottom: 0px
}

#vertical.tab-container label p {
    font-size: 13px;
    color: #606060;
    margin-bottom: 0px;
    line-height: 14px;
}

#vertical.tab-container input[type="radio"]:checked+label {
    background: #fff !important;
}

#vertical.tab-container input[type="radio"]:checked+label+.panel {
    width: 70%;
    height: 100%;
    top: 0;
    overflow: auto;
    background: #fff;
    padding: 30px 10px;
    border: 1px solid #d3d3d3;
}

#vertical.tab-container input[type="radio"]:checked+label h6 {
    color: #000 !important;
}

@media (max-width: 1000px) {
    .tab-container {
        width: 100%;
    }

    .tab-container .panel,
    .tab-container label {
        order: initial;
    }

    .tab-container label {
        width: 100% !important;
        margin-right: 0;
        margin-top: 0.2rem;
    }

    .tab-container input[type="radio"]:checked+label+.panel {
        height: unset;
    }

    #vertical.tab-container {
        width: 100%;
        height: unset;
        flex-direction: row;
    }

    #vertical.tab-container label {
        width: 100%;
        flex-basis: 100%;
    }

    #vertical.tab-container input[type="radio"]:checked+label+.panel {
        width: 100%;
    }
}

.upi small {
    font-size: 11px;
}

.upileft {
    text-align: center;
    width: 50%;
    border-right: 1px solid #d3d3d3;
}

.upi {
    display: flex;
    padding: 10px;
}

.upiright {
    padding: 10px;
}

.upiright input {
    width: 100%;
    margin-bottom: 10px;
}

.upiright {
    text-align: center;
    width: 50%;
}

.upileft span {
    font-size: 14px;
    font-weight: 600;
}

.upileft small {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
}

.upileft img {
    margin: 20px 0px;
}

.upiright .activity .activity-item .activite-label {
    min-width: 0px !important;
}

.upiright .activity .activity-item .activite-label::before {
    right: -10px;
    width: 1px;
    background-color: #888;
    background-color: rgba(33, 37, 41, 0.2);
}

.upiright .activity {
    margin-top: 20px;
}

.upiright .activity .activity-item .activity-content {
    text-align: left;
    font-size: 13px;
    color: #888;
    padding-bottom: 7px;
}

.rupees:before {
    content: "\20B9\00a0";
}

.rupees {
    font-size: 22px;
    font-weight: 700;
}

.pbtns {
    float: right;
}

.termcon {
    font-size: 13px !important;
    margin-top: 43px !important;
    line-height: 18px !important;
    color: #000 !important;
    text-align: center;
}

.upilistt {
    margin: 30px auto;
    display: block;
}

.tab-container .form-left {
    border: none;
}

.tab-container .banklist input[type="radio"] {
    opacity: 1;
    width: 10px;
    height: 10px;
    appearance: auto;
}

.banklist label {
    background: none !important;
    padding: 0px !important;
    border: none !important;
    display: inline-block !important;
}

.banklist {
    padding: 0px;
    border-bottom: 1px solid #d3d3d3;
}

.banklist li {
    list-style: none;
    margin-bottom: 22px;
}

.banklist li label img {
    width: auto !important;
    height: 25px
}



@media (max-width: 768px) {
    .login .half-field-last{ width: 100%;}
    .tab .card-body { padding: 0px;}
    .tab .card-body .nav-tabs .nav-link {
        padding: 6px 9px;}
    .doc-upload { margin-top: 53px;
    }
    .cdetails .topmenu .link {
        line-height: 21px;
}
.titless h1 {
    margin-top: 19px;
}
    .mainss {
        padding: 70px 20px !important;
    }
    .upi {
        display: block;
        padding: 10px;
    }

    .upileft,
    .upiright {
        text-align: center;
        width: 100%
    }

    .tab-container .form-left {
        padding: 0px;
    }

    .pbtns {
        margin-top: -35px;
    }

    #vertical.tab-container label {
        width: auto !important;
    }
}

.wd-100 {
    width: 100%;
}

.adtitle span,
.mainssp .minihead {
    color: #000;
    font-weight: 700;
    font-size: 13px;
}

.adtitle p {
    font-size: 12px;
    color: #808080;
    margin-bottom: 1px;
}

.mainssp .prmRoomDtlCard {
    box-shadow: 0 2px 4px #0000003d !important;
    border: none !important;
}

.psummary {
    box-shadow: 0 2px 4px #0000003d !important;
    border: none !important;
    background: #fff;
    padding: 10px 15px !important;
    border-radius: 8px;
    margin-top: 9px;
}



.wrapper-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper-2 {
    padding: 30px;
    text-align: center;
}

.wrapper-2 p {
    margin: 0;
    font-size: 15px;
    color: #888;

    letter-spacing: 1px;
}

.go-home {
    color: #fff !important;
    background: #5892FF;
    border: none;
    padding: 10px 50px;
    margin: 30px 0;
    border-radius: 30px;
    text-transform: capitalize;
    display: inline-block;

}

.footer-like {
    margin-top: auto;
    background: #D7E6FE;
    padding: 6px;
    text-align: center;
}

@media (min-width:360px) {

    .go-home {
        margin-bottom: 20px;
    }
}

@media (min-width:600px) {
    .content {
        max-width: 1000px;
        margin: 0 auto;
    }

    .wrapper-1 {
        height: initial;
        max-width: 620px;
        margin: 0 auto;
        margin-top: 50px;

    }

}

.thankyou {
    width: 70%;
    margin: 0 auto;
}

@media (max-width:768px) {
    .thankyou {
        width: 100%;
        margin: 70px auto;
    }

    .thankyou img {
        width: 100%;
    }

}

.payhead {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0px 0px 20px;
}

.paytabs .form-left {
    border: none;
    padding: 10px 10px 10px 20px
}

.dashboard .card-title {
    padding: 0px 0px;
}


.booking .card-body {
    display: flex;
}

.booking .card-title {
    padding: 0px 0px;
    font-size: 14px;
}


.booking .label {
    font-size: 13px !important;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
}

.date {
    float: right;
    margin-bottom: 3px;
    display: block;
    /* width: 100%; */
    text-align: right;
    font-size: 12px;
    background: #f2f2f2;
    right: -19px;
    top: 2px;
    padding: 3px 5px;
    position: relative;
}


.profile .profile-overview .row {
    margin-bottom: 0px !important;
    font-size: 15px;
}

.label2 {
    font-size: 14px !important;
}

.icobx {
    display: flex;
    align-items: center;
    font-size: 23px;
    font-weight: 300;
    color: blue;
}

.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: aqua !important;
}

.modal-content {
    background-color: #f2f2f2 !important;
}

.flexxcont {
    display: flex;
    justify-content: space-between;
}

.flexxcont small {
    display: block;
    font-size: 12px;
    color: darkgray;
}

.flexxcont .datee {
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 500;
    padding: 5px;
}

.flexxcont .label2 {
    margin-bottom: 4px !important;
    color: darkgray !important;
}
.flexxs { display: flex !important;
    margin-top: 10px;}

    .actiontop { display: flex; justify-content: center;}
.minihead {
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 500;
    margin-top: 8px;
    display: block;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 10px;
    padding-bottom: 6px;
}


.modal-content .card-body {
    display: block !important;
}

.modal-content .flexxcont {
    margin-bottom: 15px;
}

.labels {
    font-size: 13px !important;
}


.borderr {
    border-top: 1px dashed #888888 !important;
    border-bottom: 1px dashed #888888 !important;
    border-left: none !important;
    border-right: none !important;
    padding: 5px 0px;
    font-weight: 600;
}

.borderr small,
.borderr p {
    font-weight: 700 !important;
}

.contactt .labelee {
    font-size: 13px;
    color: darkgrey;
    margin: 5px 0px;
}

.contactt .flexxcont {
    margin-bottom: 0px !important;
}

.contactt .name {
    font-weight: 700;
    border-bottom: 1px dashed #d3d3d3;
    margin: 0px;
    padding-bottom: 7px;
    margin-bottom: 5px;
    font-size: 14px;
}

.contactt .bx {
    font-size: 19px;
    float: left;
    margin-right: 6px;
}


.booking .modal-body {
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    width: 65%;
    margin: 0 auto;
    overflow: hidden;
    padding: 13px;
    background: #fff;
    box-shadow: none;
}

.imglogo {
    width: 100px;
    margin-bottom: 20px;
}

.bookingdetails .card {
    box-shadow: none;
    border: none;
}

.bookingdetails h4.card-title {
    margin-bottom: 5px;
    padding: 0;
    font-size: 14px;
    text-align: right;
    color: #000;
}

.bookingdetails hr {
    margin: 0px 0px 10px;
}


.bookingdetails .card-title {
    margin: 0px;
}

.bookingdetails .flexxcont .datee {
    font-size: 15px !important;
}

.pdetails {
    border: 1px solid #d3d3d3;
    border-left: none;
    border-right: none;
    border-top: none;
    margin-top: -11px !important;
}

.dblock {
    padding: 11px 21px;
    border-right: 1px solid #d3d3d3;
    text-align: center;
}

.dblock:last-child {
    border-right: none !important;
}

.payment,
.contactt {
    display: block !important;
}

.payment {
    padding-left: 20px !important;
    border-left: 1px solid #d3d3d3;
}

.payment .label {
    color: #000 !important;
}

.bottomarea {
    text-align: center;
}
.img-fluid {
    max-width: 100%;
    height: auto;
    width: 100%;
}
.borderrs {
    border-top: 1px solid #d3d3d3;
    height: 8px;
    background: #f2f2f2;}
@media (max-width: 768px) {
    .tmain { margin: 63px 0px;}
    
    .bookingdetails{ margin: 70px auto;}

    .contactt,
    .payment {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        padding: 10px !important;
    }

    .booking img.imglogo {
        width: 100px !important;
        margin: 0px auto 20px auto !important;
        display: block;
    }

    .bookingdetails h4.card-title {
        text-align: center;
    }

    .bookingdetails .pdetails {
        display: block !important;
    }

    .bookingdetails .modal-body {
        width: 100% !important;
    }

    .dblock:last-child {
        border-right: 1px solid #d3d3d3 !important;
    }

    .dblock {
        padding: 4px 1px;
        width: 49%;
        display: inline-block;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border: none !important;
        margin-bottom: 4px;
    }

    .pdetails small {
        display: inline-block !important;
    }

    .pdetails {
        border-bottom: none;
    }

    .payment {
        border-left: none;
        padding-left: none;
        margin-top: 35px;
    }
}

.checkboxesfield {
    display: flex;
    align-items: center;
}

.login-area {
    padding: 20px 0px;
}

.login-area p {
    margin-bottom: 0px;
    margin-right: 10px;
}

.single-field {
    margin-bottom: 18px;
}

.form-control {
    font-size: 14px !important;
}

.login-form ul {
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
}

.listing li {
    display: block !important;
    font-size: 14px;
    margin-bottom: 10px;
}

.booking-table table {
    border: 1px solid #d3d3d3;
}

.table-responsive .table th {
    height: 25px;
    background: #f2f2f2;
    white-space: nowrap;
    font-size: 14px;
}

.booking-table td {
    border-right: 1px solid #d3d3d3;
    font-size: 14px;
}

.single-field label {
    font-size: 14px;
    margin-right: 10px;
}

.single-fields {
    display: flex;
    align-items: flex-start;
}

.single-fields label {
    margin: -5px 0px 0px 5px;
}

.login .card-body {
    padding: 30px 20px 20px 20px;
}

.terms {
    font-size: 13px;
}

.logint {
    margin-bottom: 13px;
    font-size: 18px !important;
}
.google {
    background: #fff;
    padding: 20px;
    border-radius: 100px;
    border: 2px solid #d3d3d3;
    width: 41px;
    height: 37px;
    padding: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.google img {
    width: 31px;

}

.single-camp-hi {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.single-camp-hi:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-color:rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    border-radius: 10px;
    z-index: 1;
}

.single-camp-hi:hover img {
    transform: scale(1.2);
}

.single-camp-hi img {
    transition: 0.3s;
    transform: scale(1);
    width: 100%;
}

.prmRoomDtlCard__inner {
    background-repeat-x: no-repeat;
    background-position-x: calc(100% + 20px);
    flex: 1;
    padding: 12px 16px;
}

.lgnPersuation.red {
    background: #ffe7e7;
    border: 1px solid #c4c4c4;
    border-radius: 58px;
    display: flex;
    padding: 10px 17px;
    margin: 0 auto 10px auto;
}

.dtlSprite.icLigtning {
    background-position: -245px -175px;
    height: 17px;
    width: 16px;
}

.lgnPersuation.red .lgnPersuation__text {
    color: #eb2026;
    margin-bottom: 0px;
}

.lgnPersuation__text {
    font-size: 12px;
    font-weight: 500;
}

.prmRoomDtlCard__inner {
    background-repeat-x: no-repeat;
    background-position-x: calc(100% + 20px);
    flex: 1;
    padding: 12px 16px;
}

.blackText {
    color: #000;
}

.makeFlex {
    display: flex;
}

.prmRoomDtlCard__left {
    flex: 1;
    margin-right: 14px;
}

.makeFlex.hrtlCenter {
    align-items: center;
}

.dtlSprite.icAdults {
    background-position: -246px -19px;
    height: 10px;
    width: 12px;
}

.dtlSprite {
    background: url(//imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/sprite_prod/premium-details-sprite_hashf003c6a….png) no-repeat;
    background-size: 300px 400px;
    display: inline-block;
    flex-shrink: 0;
    font-size: 0;
}

.font12 {
    font-size: 12px;
    line-height: 12px;
}

.latoBold {
    font-weight: 700;
}

.prmRoomFacilities {
    display: block;
    padding: 0px;
    margin-bottom: 0px;
}

.onFcHover {
    cursor: pointer;
    position: relative;
}

.prmRoomFacilities__item {
    display: flex;
}

.prmRoomFacilities__item--icon {
    display: inline-flex;
    flex-shrink: 0;
    margin-right: 5px;
    padding-top: 5px;
    width: 13px;
}

.dtlSprite.icSmallGreenTick {
    background-position: -267px -22px;
    height: 6px;
    width: 7px;
}

.prmRoomFacilities__item--text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
}

:root .greenText {
    --borderColor: #00a19c;
}

.greenText {
    color: #1a7971;
}

.prmRoomDtlCard__right {
    flex-shrink: 0;

    text-align: right;
}

.prmRoomDtlCard__right {
    flex-shrink: 0;
    text-align: right;
    margin-top: 8px;
}

.maxWidth170 {
    max-width: 170px;
}

.font12 {
    font-size: 12px;
    line-height: 12px;
    color: grey;
}

.grayText {
    color: #9b9b9b;
}

.font16 {
    font-size: 16px;
    line-height: 16px;
}

.cstmTooltipHover {
    position: relative;
}

.prmRoomDtlCard {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.lgnPersuation i {
    color: red;
    margin-right: 8px;
}

.lineThrough {
    text-decoration: line-through;
    margin-bottom: 0px;
}

.blackText {
    color: #000;
}

.latoBlack {
    font-weight: 500;
}

.prmRoomDtlCard__right p {
    margin-bottom: 3px;
}

.redText {
    color: red;
}

.appendTop5 {
    font-weight: 500;
}

.pr i {
    font-size: 12px;
    color: grey;
    margin-right: 4px;
}

.heading {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: #000;
}

.prmRoomDtlCard__footer {
    align-items: center;
    border-top: 1px solid #c4c4c4;
    display: flex;
    justify-content: space-between;
    padding: 6px 17px;
}

.prmRoomDtlCard__link {
    align-items: center;
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
}

.prmRoomDtlCard__footer .bookNowBtn {
    flex: 1;
    text-align: right;
}

.primaryBtn {
    background-image: linear-gradient(96deg, #53b2fe, #065af3);
    border: 0;
    border-radius: 34px;
    box-shadow: 0 1px 7px 0 #0003;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    outline: 0;
    padding: 9px 20px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    /* margin-bottom: 5px; */
    display: inline-block;
}

.titless h1 {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0px;
}

.titless p {
    font-size: 13px;
}

a {
    text-decoration: none !important;
}

.checkboxesfield input {
    margin-right: 2px;
}

.mti {
    color: red;
    font-size: 20px;
}

.uico {
    font-size: 18px;
    color: #888;
    height: 18px !important;
    float: left;
}

.prmRoomFacilities__item--text {
    margin-bottom: 3px !important;
}

.price {
    font-size: 18px;
}

.login .form-control,
.main .form-control {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #d3d3d3 !important;
}

.payhead {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin: 10px 0px 0px 20px !important;
}

.ig {
    margin-bottom: 10px;
}



.mainblock {
    padding: 20px !important;
}

.profiless {
    display: block !important;
}

/*.login { display: flex; align-items: center; justify-content: center; background: #f2f2f2;  height: 100vh;}*/
.login {
    background: #f2f2f2;
    height: 100%;
}

.bylogo {
    width: 112px;
    margin: 0 auto 10px auto;
    display: block;
}

.login h2 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
}

.logins {
    height: 100vh !important;
}

.login .mat-mdc-card {
    background: #fff;
    padding: 20px;
}

.login .mat-mdc-form-field {
    width: 100%;
}

.login-box {
    width: 35%;
    margin: 0 auto;
}

.login .card {
    background: #fff;
    padding: 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.login button {
    width: 50%;
    margin: 20px auto;
    display: block;
}

.google {
    background: #fff;
    padding: 20px;
    border-radius: 100px;
    border: 2px solid #d3d3d3;
    width: 0px;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.google img {
    width: 31px;
    margin: 0px;
}

.glogin {
    color: #888;
    font-size: 14px;
    display: block;
    margin: 20px auto 5px auto;
    text-align: center;
}

.login .small {
    text-align: center;
}

.login .small a {
    text-decoration: none;
}
.custcontent .main {
    padding: 48px 0px;
}
.tableresp table { width: 100%;}
.tableresp thead th {
    background: #f2f2f2;
    font-size: 14px;
    white-space: nowrap;
    /* line-height: 42px; */
    padding: 9px 5px;
    font-weight: 500;
}
.tableresp tbody th, .tableresp tbody td{    font-weight: 400;
    font-size: 14px;
    padding: 0px 5px;
    border-bottom: 1px solid #d3d3d3;
    vertical-align: middle;}
@media only screen and (max-width:768px) {
    .tableresp{
        overflow-x: scroll;}
    .tableresp table th, .tableresp table td{ white-space: nowrap;}
    .login button {
        width: auto;
    }

    .login-box {
        width: 90% !important;
        margin: 0 auto;
    }
}

.buttons {
    color: #fff;
    background: #0d6efd;
    border: none;
    padding: 13px 10px;
    border-radius: 8px;
    text-transform: uppercase;
    display: block;
    width: 50%;
    text-align: center;
    margin: 5px auto;
}

.login .form-control,
.main .form-control {
    outline: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border-bottom: 1px solid #D3D3D3 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none;
}

.form-label {
    color: #6c757d;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-bottom: 5px;
}


.terms {
    font-size: 14px;
    color: #888;
}

.form-label {
    margin-bottom: 0px !important;
}

.uderd {
    list-style: none !important;
    margin-top: 14px !important;
}

.redn {
    font-size: 20px;
    color: red;
}

.icoo {
    font-size: 20px;
}


#header .rx_icon {
    float: right;
    width: 30px;
}

/* Drawer Library */
.rx_noselect {
    /* iOS Safari */
    -webkit-touch-callout: none;
    /* Chrome/Safari/Opera */
    -webkit-user-select: none;
    /* Konqueror */
    -khtml-user-select: none;
    /* Firefox */
    -moz-user-select: none;
    /* Internet Explorer/Edge */
    -ms-user-select: none;
    /* Non-prefixed version, currently
                                    not supported by any browser */
    user-select: none;
}

.drawer_bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 61px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    opacity: 0.001;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    visibility: hidden;
}

.drawer {
    max-width: 320px;
    width: 75%;
    height: 100%;
    left: 0px;
    top: 61px;
    bottom: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    background: white;
    position: fixed;
    z-index: 5;
    opacity: 0.001;
}

.drawer .label {
    position: absolute;
    top: 56px;
    bottom: 0;
    width: 32px;
    right: -32px;
}

.drawer .antiSelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
}

.main {
    padding: 70px 20px;
}

.bookingss .card-body {
    color: #000;
}

.bookingss .card-body p {
    color: #888;
}

.sidebar-nav {
    padding-left: 20px !important;
}

.sidebar-nav li {
    list-style: none;
    margin-top: 10px;
}

.sideico {
    font-size: 18px !important;
    ;
    margin-right: 4px !important;
    ;
    margin-top: -3px !important;
    ;
}

.profile .profile-card .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
}

.profile .profile-card h2 {
    font-size: 24px;
    font-weight: 500;
    color: #2c384e;
    margin: 10px 0 0 0;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
    height: 100px;
}

.dashboard .card-body .MuiSvgIcon-root {
    font-size: 32px
}

.dashboard .card-body h6 {
    font-size: 37px;
    margin-top: 5px;
}

.dashboard .sales-card .card-icon {
    color: #4154f1;
    background: #f6f6fe;
    padding:15px;
    border: 1px solid #E0E0E0
}

.dashboard .revenue-card .card-icon {
    color: #2eca6a;
    background: #e0f8e9;
    padding: 15px;
    border: 1px solid #E0E0E0
}

.dashboard .customers-card .card-icon {
    color: #ff771d;
    background: #ffecdf;
    padding: 15px;
    border: 1px solid #E0E0E0
}

.dashmain {
    background-color: #f8f8f8;
    padding: 74px 20px;
}

.dashmain .card {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border: none;
    margin-bottom: 10px;
}

.dashmain .card-title {
    padding: 0px 0px;
    color: #383838;
    font-weight: 600;
    font-size: 15px;
}


.news {
    display: flex;
}

.news h3 {
    margin: 21px 14px;
    text-align: left !important;
    font-weight: 400;
    font-size: 22px;
}

.news h3 b {
    display: block
}

.pulse2 {
    font-size: 22px !important;
}

.btnupdate {
    width: 88px;
}


.custcontent {
    background: #f8f8f8;
}

@media (max-width: 1199px) {

    .custcontent {
        display: block !important;
    }

    .deskdraw {
        display: none !important;
    }

    .custcontent .MuiBox-root {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .icobtnns {
        display: none !important;
    }
}
.required{
    color: red;
}
.text-blue{color: blue;}
.text-green{color: green;}
.text-red{color: red;}



.sidebar-nav li .MuiSvgIcon-root{
    font-size: 16px;
    margin: -2px 4px 0px 0px; }
    .dropnav { 
        float: right;
        margin: 0px 10px 0px 0px !important;
        font-size: 18px !important;}


#components-nav{padding-left: 19px;}
#components-nav li a { color: #000;}
.dropmenu li{ font-size: 14px;}
.dropmenu .MuiSvgIcon-root { font-size: 23px !important;}




.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  .popup .content { margin:20px 0px}
  
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: #06D85F;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  .popup .content .MuiFormControl-root { margin: 20px 0px 30px 0px;}
  
  @media screen and (max-width: 700px){
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  }
  .header-sidebar {
    position: relative;
    z-index: 99;}

    .custcontent .MuiDrawer-paper {
        z-index: 99;}
    
   
.sec-center {
    position: relative;
    max-width: 100%;
    text-align: center;
    z-index: 9;
  }
 .custcontent [type="checkbox"]:checked,
  .custcontent [type="checkbox"]:not(:checked){
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
  }
  .dark-light:checked ~ .light-back{
    opacity: 1;
  }
  .dropdown:checked + label,
  .dropdown:not(:checked) + label{
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    color: #000;
  }
  .dark-light:checked ~ .sec-center .for-dropdown{
    background-color: #000;
    color: #ffeba7;
    box-shadow: 0 12px 35px 0 rgba(16,39,112,.25);
  }
  .dropdown:checked + label:before,
  .dropdown:not(:checked) + label:before{
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: auto;
    pointer-events: none;
  }
  .dropdown:checked + label:before{
    pointer-events: auto;
  }
  .section-dropdown {
    position: absolute;
    padding: 5px;
    background-color: #fff;
    top: 28px;
    right:37px;
    text-align: left;
    width: 200px;
    border-radius: 4px;
    display: block;
    box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
  }
  .dark-light:checked ~ .sec-center .section-dropdown {
    background-color: #fff;
    box-shadow: 0 14px 35px 0 rgba(9,9,12,0.15);
  }
  .dropdown:checked ~ .section-dropdown{
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
  
  
  @media screen and (max-width: 991px) {
  
  .dark-light:checked + label,
  .dark-light:not(:checked) + label{
    top: 20px;
    right: 20px;
  }
  }
  .dd-menu {padding-left: 0px;
    margin: 0;}
  .dd-menu li { list-style: none;
    line-height: 30px;}
  .dd-menu li a { color: #000;
    width: 100%;
    display: block;}
.bankdetails{
    border-bottom: 1px dashed #d3d3d3;
    margin-bottom: 20px;
    background: #f0f8ff;
    margin-top: -10px;
    padding-top: 10px;}
.bankdetails p {
    color: #888;
    margin: 5px 19px 20px 19px;
    font-size: 15px;
    display: block;
    float: left;
    border: 1px dashed #d3d3d3;
    padding: 10px;}
.bankdetails ul{
            font-size: 14px;
            padding-left: 40px;}
.bankdetails li{list-style: circle;}
.custcontent .booking .modal-body{width: 87% !important;}
.even-row {background-color: #f2f2f2;}
.odd-row { background-color: #ffffff;}


.button-row {
    width: 800px;
    margin: 0 auto 20px auto;
    text-align: center;}
    .button-row button { margin: 0px 3px;}


    .tabs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
      }
      
      .tabs .tablabel {
        order: 1;
        display: block;
        padding:5px 18px;
        margin-right: 0.2rem;
        cursor: pointer;
        color: #505050;
        background: #fff;
        font-weight: 500;
        border-bottom: 3px solid #d3d3d3;
        transition: background ease 0.2s;
      }
      
      .tabs .tab {
        order: 99;
        flex-grow: 1;
        width: 100%;
        display: none;
        padding: 0px;
        background: #fff;
      }
      
      .tabs input[type=radio] {
        display: none;
      }
      
      .tabs input[type=radio]:checked + .tablabel {
        background: #fff;
        border-bottom: 3px solid #90CAF9;
      }
      
      .tabs input[type=radio]:checked + .tablabel + .tab {
        display: block;
      }
      
      @media (max-width: 45em) {
        .tabs .tab,
      .tabs .tablabel {
          order: initial;
        }
      
        .tabs .tablabel {
          width: 100%;
          margin-right: 0;
          margin-top: 0.2rem;
        }
      }
      .brdetails { color: #000;}
      .doc-upload {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* background: linear-gradient(to right, #4568dc, #b06ab3); */
        color: #D7D7EF;
        font-family: "Lato", sans-serif;
        color: #000;
      }
      
      .file-drop-area {
        position: relative;
        display: flex;
        align-items: center;
        width: 450px;
        max-width: 100%;
        padding: 25px;
        border: 1px dashed rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        transition: 0.2s;
        margin-bottom: 20px;
      }
      .file-drop-area.is-active {
        background-color: rgba(255, 255, 255, 0.05);
      }
      
      .fake-btn {
        flex-shrink: 0;
        background-color: rgba(255, 255, 255, 0.04);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 3px;
        padding: 8px 15px;
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
      }
      
      .file-msg {
        font-size: small;
        font-weight: 300;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      .file-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
      }
      .file-input:focus {
        outline: none;
      }

      .img{
        height: 120px;
        width: 120px;
      }
      .steps{ 
        padding: 10px;
        margin: 10px;
        padding-top: 0px;
        margin-top: 0px;}
.steps ul{ padding-left: 0px;}
      .steps li{list-style: none; text-align: left;
        margin-bottom: 5px;
        font-size: 14px;}
      .thankyou .wrapper-1 { margin-top: 0px;}
      .thankyou .wrapper-2 { padding: 10px; text-align: left; border-bottom:1px dashed #d3d3d3;
        margin: 10px;}
        .infeedback { display: block; width: 100%;}
        #loadingg { background-color:rgba(0, 0, 0, .7); display: flex; justify-content: center; align-items: center; height: 100vh; width: 100%; position: fixed; z-index: 99999; top: 0;}
         
        ing-dots-loader {
width: 100px;
height: 100px;
border-radius: 100%;
position: relative;
margin: 0 auto;
}

.jumping-dots-loader span {
display: inline-block;
width: 20px;
height: 20px;
border-radius: 100%;
background-color:rgb(256, 256, 256);
margin: 35px 5px;
}

.jumping-dots-loader span:nth-child(1) {
animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {
0%,
75%,
100% {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

25% {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
}
}

.half-field-last {
    float: left;
    width: 48%;
    margin: 0px 4px;
}